import { DatePickerComplex } from "@components/forms/DatePickerComplex";
import { Box } from "@swan-io/lake/src/components/Box";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { Pressable, StyleSheet, Text, View, ViewStyle } from "react-native";
import { borderColorVariants, fontColorVariants } from "../styles/constants";
import { encodeDateISO } from "../utils/date";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  calendar: {
    gap: 12,
    userSelect: "none",
    paddingVertical: 10,
    paddingHorizontal: 20,
    color: fontColorVariants.gray500,
    borderWidth: 1,
    borderColor: borderColorVariants.gray300,
    borderRadius: 6,
  },
  calendarText: {
    color: fontColorVariants.gray600,
    fontWeight: "600",
  },
});

type Props = {
  dateFrom?: Date;
  dateTo?: Date;
  setDates?: (dates: { dateFrom: string; dateTo: string }) => void;
  style?: ViewStyle;
  open?: boolean;
  onOpen?: (open: boolean) => void;
};

export const Calendar = ({ dateFrom, dateTo, setDates, style, open = false, onOpen }: Props) => {
  const [openCalendar, setOpenCalendar] = useState(open);

  const [from, setFrom] = useState(dateFrom);
  const [to, setTo] = useState(dateTo);

  useEffect(() => {
    onOpen?.(open);
  }, [open]);

  return (
    <View>
      <Pressable onPress={() => setOpenCalendar(!openCalendar)}>
        <Box direction="row" style={styles.calendar}>
          <Text style={styles.calendarText}>
            {from && to
              ? `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`
              : t("common.date")}
          </Text>

          <FaChevronDown size={14} />
        </Box>
      </Pressable>

      {openCalendar && (
        <DatePickerComplex
          from={from}
          to={to}
          onChange={dates => {
            const [start, end] = dates;
            setFrom(start ?? from);
            setTo(end ?? to);

            setDates?.({
              dateFrom: encodeDateISO(start?.toString() as string),
              dateTo: encodeDateISO(end?.toString() as string),
            });

            setOpenCalendar(false);
          }}
          style={style}
        />
      )}
    </View>
  );
};
