import { DatePicker } from "@components/forms/DatePicker";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useEffect } from "react";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { Button } from "../../../components/forms/Button";
import { FormEnter } from "../../../components/forms/FormEnter";
import { ChargeSalesInvoiceDocument, OperationInfo } from "../../../graphql/crm";
import { encodeDate } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { handlerFieldErrors, validateRequired } from "../../../utils/validations";

type Props = {
  visible: boolean;
  onPressClose: () => void;
  invoiceId: string;
  chargeDate: string;
};

type ChangeStatusForm = {
  paymentDate: string;
  invoiceId: string;
};

export const SalesUpdateStatusModal = ({ visible, onPressClose, invoiceId, chargeDate }: Props) => {
  const [, chargeSalesInvoice] = useUrqlMutation(ChargeSalesInvoiceDocument);

  const { Field, submitForm, setFieldError, setFieldValue, resetForm } = useForm<ChangeStatusForm>({
    paymentDate: { initialValue: chargeDate, validate: validateRequired },
    invoiceId: { initialValue: invoiceId, validate: validateRequired },
  });

  useEffect(() => {
    if (chargeDate !== "") {
      setFieldValue("paymentDate", chargeDate);
    }
  }, [chargeDate]);

  const handleSubmit = () => {
    submitForm(values => {
      chargeSalesInvoice({
        input: {
          paymentDate: values.paymentDate !== undefined ? encodeDate(values.paymentDate) : "",
          id: invoiceId,
        },
      })
        .mapOk(data => {
          match(data.chargeSalesInvoice)
            .with({ __typename: "OperationInfo" }, () =>
              handlerFieldErrors<ChangeStatusForm>(
                data.chargeSalesInvoice as OperationInfo,
                setFieldError,
              ),
            )
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("invoices.charge.success"),
                autoClose: true,
              });
              resetForm();
              onPressClose();
            });
        })
        .mapError(() => {
          showToast({ variant: "error", title: t("invoices.charge.error"), autoClose: true });
          resetForm();
          onPressClose();
        });
    });
  };

  return (
    <LakeModal visible={visible} onPressClose={onPressClose} title={t("invoices.charge")}>
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {() => (
          <FormEnter onSubmit={handleSubmit}>
            <Box
              onTouchEnd={e => {
                e.stopPropagation();
              }}
            >
              <Field name="paymentDate">
                {({ value, onChange, error }) => (
                  <DatePicker
                    format={locale.dateFormat}
                    firstWeekDay={locale.firstWeekday}
                    value={value}
                    onChange={onChange}
                    label={t("invoices.paymentDate")}
                    error={error}
                  />
                )}
              </Field>
            </Box>

            <Box alignItems="end">
              <Button onPress={handleSubmit} icon={<SaveIcon />} reverse={true}>
                {t("common.save")}
              </Button>
            </Box>
          </FormEnter>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
