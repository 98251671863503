import { DecimalInput } from "@components/DecimalInput";
import { DatePicker } from "@components/forms/DatePicker";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { Button, ButtonGroup } from "../../../components/forms/Button";
import Input from "../../../components/forms/Input";
import { CreateTicketState } from "../../../features/invoices/types";
import {
  CreateTicketDocument,
  SupplierRelay,
  SupplierRelayEdge,
  SuppliersDocument,
  Ticket,
  TicketRelay,
  TicketStatus,
  UpdateTicketDocument,
} from "../../../graphql/crm";
import { common } from "../../../styles/common";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { encodeDate, encodeDateISO, getToday } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { Router } from "../../../utils/routes";
import {
  extractQueryData,
  handlerErrors,
  tapError,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { SupplierCreate } from "./SupplierCreate";

const styles = StyleSheet.create({
  contain: {
    backgroundColor: backgroundColorVariants.white,
    paddingHorizontal: 32,
    paddingVertical: 56,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    width: "96dvw",
    maxWidth: 900,
    marginBottom: 16,
  },
  title: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 24,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 24,
  },
  columnMin: {
    width: 300,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
    minWidth: 190,
  },
  gap: {
    gap: 12,
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
  total: {
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: borderColorVariants.gray400,
    marginTop: 6,
    paddingTop: 6,
  },
});

type Props = {
  ticket?: TicketRelay;
  ner?: boolean;
  ocrTicket?: Ticket;
  onRefreshRequest?: () => void;
};

export const TicketEditor = ({ ticket, onRefreshRequest, ner = false, ocrTicket }: Props) => {
  const showDraftButton = ticket === undefined || ticket.status === "DRAFT";
  const { Field, setFieldValue, submitForm } = useForm<CreateTicketState>({
    issuerId: {
      initialValue: isNotNullish(ticket?.issuer?.id) ? Number(ticket?.issuer.id) : undefined,
      validate: validateNumericNullableRequired,
    },
    seriesCurrent: { initialValue: ticket?.seriesCurrent ?? "" },
    issueDate: {
      initialValue: isNotNullish(ticket?.issueDate) ? encodeDateISO(ticket?.issueDate) : getToday(),
      validate: validateRequired,
    },
    paymentDate: {
      initialValue: isNotNullish(ticket?.paymentDate)
        ? encodeDateISO(ticket?.paymentDate ?? "")
        : isNotNullish(ticket?.issueDate)
          ? encodeDateISO(ticket?.issueDate)
          : getToday(),
      validate: validateRequired,
      strategy: "onSubmit",
    },
    status: { initialValue: ticket?.status ?? "DRAFT" },
    notes: { initialValue: ticket?.notes ?? "" },
    total: {
      initialValue: (ticket?.total as string) ?? "0.00",
      validate: validateRequired,
    },
  });

  useEffect(() => {
    if (isNotNullish(ocrTicket)) {
      reloadSuppliers();
      setFieldValue(
        "issuerId",
        Boolean(ocrTicket.issuer) ? Number(ocrTicket.issuer?.id) : undefined,
      );
      setFieldValue("seriesCurrent", ocrTicket.seriesCurrent as string);
      setFieldValue(
        "issueDate",
        isNotNullish(ocrTicket.issueDate) ? encodeDateISO(ocrTicket.issueDate) : "",
      );
      setFieldValue(
        "paymentDate",
        isNotNullish(ocrTicket.paymentDate)
          ? encodeDateISO(ocrTicket.paymentDate)
          : isNotNullish(ocrTicket.issueDate)
            ? encodeDateISO(ocrTicket.issueDate)
            : "",
      );
      setFieldValue("notes", ocrTicket.notes as string);
      setFieldValue("status", ocrTicket.status);
      setFieldValue("total", (ocrTicket.total as string) ?? "0.00");
    }
  }, [ocrTicket]);

  const [showSupplierCreate, setShowSupplierCreate] = useState(false);
  const [suplier, setSupplier] = useState<SupplierRelay | undefined>();

  const { data: suppliersQuery, reload: reloadSuppliers } = useUrqlQuery(
    { query: SuppliersDocument, variables: { first: 100, filters: {} } },
    [],
  );
  const suppliers = extractQueryData(
    suppliersQuery,
    "value.value.suppliers.edges",
  ) as SupplierRelayEdge[];

  const [, createTicket] = useUrqlMutation(CreateTicketDocument);
  const [, updateTicket] = useUrqlMutation(UpdateTicketDocument);

  const handleSubmit = (draft: boolean) => {
    submitForm(values => {
      const input = {
        seriesCurrent: values.seriesCurrent,
        issuerId: Number(values.issuerId),
        issueDate: values.issueDate !== undefined ? encodeDate(values.issueDate) : "",
        paymentDate: values.paymentDate !== undefined ? encodeDate(values.paymentDate) : "",
        notes: values.notes,
        total: values.total ?? 0,
        status: draft ? ("DRAFT" as TicketStatus) : ("PAID" as TicketStatus),
      };
      if (ticket !== undefined || ner) {
        Object.assign(input, { id: ner ? ocrTicket?.id : ticket?.id });
        updateTicket({ input })
          .mapOk(data => {
            match(data.updateTicket)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesTicketList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      } else {
        createTicket({ input })
          .mapOk(data => {
            match(data.createTicket)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesTicketList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      }
    });
  };

  return (
    <View>
      <Space height={12} />

      <ResponsiveContainer breakpoint={breakpoints.medium}>
        {({ large }) => (
          <View style={styles.contain}>
            <View>
              <Box
                direction={large ? "row" : "column"}
                alignItems={large ? "end" : "stretch"}
                justifyContent="spaceBetween"
                style={styles.gap}
              >
                <View style={styles.columnMin}>
                  <Text style={styles.title}>{t("invoices.ticket")}</Text>
                  <Space height={32} />

                  <Field name="seriesCurrent">
                    {({ value, onChange, error }) => (
                      <LakeLabel
                        label={t("invoices.ticket.number")}
                        render={id => (
                          <LakeTextInput
                            id={id}
                            value={value}
                            onChangeText={onChange}
                            hideErrors={error === undefined}
                            error={error}
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Space height={20} />
                </View>

                <View style={large && styles.column}>
                  <Box
                    direction="row"
                    justifyContent="spaceBetween"
                    style={[styles.gap, common.wrap]}
                  >
                    <View style={styles.column}>
                      <Field name="issueDate">
                        {({ value, onChange, error }) => (
                          <DatePicker
                            format={locale.dateFormat}
                            firstWeekDay={locale.firstWeekday}
                            value={value}
                            onChange={onChange}
                            label={t("invoices.issueDate") + "*"}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>

                    <View style={styles.column}>
                      <Field name="paymentDate">
                        {({ value, onChange, error }) => (
                          <DatePicker
                            format={locale.dateFormat}
                            firstWeekDay={locale.firstWeekday}
                            value={value}
                            onChange={onChange}
                            label={t("invoices.paymentDate") + "*"}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>
                  </Box>

                  <Text style={styles.subtitle}>{t("invoices.supplierData")}</Text>

                  <Field name="issuerId">
                    {({ value, onChange, error }) => (
                      <LakeLabel
                        label={t("contact.supplier")}
                        render={() => (
                          <LakeSelect
                            items={
                              suppliers?.map(item => ({
                                name: `${item.node.name} ${item.node.nif != null ? ` (${item.node.nif})` : ""}`,
                                value: parseInt(item.node.id as string),
                              })) ?? []
                            }
                            value={value}
                            onValueChange={onChange}
                            hideErrors={error === undefined}
                            error={error}
                          />
                        )}
                        style={styles.actions}
                        actions={
                          <Box direction="row" justifyContent="spaceBetween">
                            <Pressable
                              style={styles.action}
                              onPress={() => {
                                setSupplier(undefined);
                                setShowSupplierCreate(true);
                              }}
                            >
                              <Icon
                                name="add-filled"
                                size={12}
                                color={fontColorVariants.neutral500}
                              />

                              <Text style={styles.actionText}>{t("invoices.newSupplier")}</Text>
                            </Pressable>

                            {value != null && (
                              <Pressable
                                style={styles.action}
                                onPress={() => {
                                  const customerData = suppliers?.find(
                                    c => c.node.id === value.toString(),
                                  )?.node;
                                  setSupplier(customerData);
                                  setShowSupplierCreate(true);
                                }}
                              >
                                <Icon
                                  name="edit-regular"
                                  size={12}
                                  color={fontColorVariants.neutral500}
                                />

                                <Text style={styles.actionText}>{t("invoices.editSupplier")}</Text>
                              </Pressable>
                            )}
                          </Box>
                        }
                      />
                    )}
                  </Field>

                  {showSupplierCreate && (
                    <SupplierCreate
                      visible={showSupplierCreate}
                      supplier={suplier}
                      onClose={() => {
                        reloadSuppliers();
                        setShowSupplierCreate(false);
                      }}
                    />
                  )}
                </View>
              </Box>

              <Space height={32} />

              <Field name="notes">
                {Input({
                  label: t("invoices.notes"),
                })}
              </Field>

              <Space height={24} />

              <Box direction="row" justifyContent="end" style={styles.total}>
                <Field name="total">
                  {({ value, onChange, error }) => (
                    <LakeLabel
                      label={t("invoices.total")}
                      render={id => (
                        <DecimalInput
                          id={id}
                          value={String(value)}
                          error={error}
                          onChangeDecimal={onChange}
                        />
                      )}
                    />
                  )}
                </Field>
              </Box>
            </View>
          </View>
        )}
      </ResponsiveContainer>

      <Box direction="row" justifyContent="end">
        <Button
          mode="tertiary"
          size="large"
          disabled={false}
          onPress={() => Router.push("InvoicesTicketList", { visible: undefined })}
        >
          {t("common.cancel")}
        </Button>

        <ButtonGroup transparent={false}>
          {showDraftButton && (
            <Button
              style="group"
              size="large"
              disabled={false}
              icon={<SaveIcon />}
              reverse={true}
              onPress={() => handleSubmit(true)}
            >
              {t("invoices.draft")}
            </Button>
          )}

          <Button
            style="group"
            size="large"
            disabled={false}
            icon={<SaveIcon />}
            reverse={true}
            onPress={() => handleSubmit(false)}
          >
            {t("common.save")}
          </Button>
        </ButtonGroup>
      </Box>
    </View>
  );
};
