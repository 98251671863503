import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DateCell } from "@components/forms/DateCell";
import { DefaultCell } from "@components/forms/DefaultCell";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ReactElement, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { EmployeeRelay, EmployeesStaticDocument, Static } from "../../../graphql/crm";
import { encodeDateISO } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { extractQueryData } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";
import { EmployeeAssignment } from "./employees/EmployeeAssignment";
import KebabMenu from "./employees/KebabMenu";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  data: EmployeeRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<EmployeeRelay, ExtraInfo>) => ReactElement;
};
type ExtraInfo = undefined;

export const DetailsList = ({
  data,
  loading,
  onEndReached,
  activeRowId,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
}: Props) => {
  const { data: statics } = useUrqlQuery({ query: EmployeesStaticDocument }, []);
  const [showAssignmentModal, setShowAssignmentModal] = useState<boolean>(false);
  const [employeeAssignmentId, setEmployeeAssignmentId] = useState<string>("");

  const categories = extractQueryData(
    statics,
    "value.value.employeesStatic.categories",
  ) as Static[];

  const contractTypes = extractQueryData(
    statics,
    "value.value.employeesStatic.contractTypes",
  ) as Static[];

  const columns: ColumnConfig<EmployeeRelay, ExtraInfo>[] = [
    {
      id: "start_end_date",
      width: 100,
      title: t("hr.start_end_date"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => (
        <DefaultCell
          data={`${encodeDateISO(item.startDate ?? "")} ${encodeDateISO(item.endDate ?? "-")}`}
        />
      ),
    },
    {
      id: "variation_date",
      width: 80,
      title: t("hr.variation"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DateCell date={item.variationDate ?? ""} />,
    },
    {
      id: "name",
      width: "grow",
      title: t("hr.name"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.name} />,
    },
    {
      id: "nif",
      width: 90,
      title: t("contact.nif"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.nif ?? ""} />,
    },
    {
      id: "num_ss",
      width: 110,
      title: t("hr.numSs"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.numSs ?? ""} />,
    },
    {
      id: "category",
      width: "grow",
      title: t("hr.category"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => (
        <DefaultCell data={categories?.find(c => c.value === item.category)?.name ?? ""} />
      ),
    },
    {
      id: "contractType",
      width: "grow",
      title: t("hr.contractTypeContracted"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => (
        <DefaultCell data={contractTypes?.find(c => c.value === item.contractType)?.name ?? ""} />
      ),
    },
    {
      id: "percentIrpf",
      width: 70,
      title: t("hr.percentIrpf"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={`${(item.percentIrpf as string) ?? "0.00"}%`} />,
    },
    {
      id: "options",
      width: 31,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <KebabMenu
          employeeId={item.id as string}
          setEmployeeAssignmentId={setEmployeeAssignmentId}
          setShowAssignmentModal={setShowAssignmentModal}
        />
      ),
    },
  ];

  const smallColumns: ColumnConfig<EmployeeRelay, ExtraInfo>[] = columns.filter(c =>
    ["start_end_date", "name", "nif", "num_ss", "options"].includes(c.id),
  );

  return (
    <>
      <PlainListView
        withoutScroll={true}
        data={data}
        keyExtractor={item => item.id as string}
        headerHeight={36}
        rowHeight={63}
        headerStyle={styles.header as ViewStyle}
        headerBackgroundColor="#EDEDED"
        groupHeaderHeight={36}
        extraInfo={undefined}
        columns={columns}
        smallColumns={smallColumns}
        activeRowId={activeRowId}
        onActiveRowChange={onActiveRowChange}
        loading={loading}
        onEndReached={onEndReached}
        getRowLink={getRowLink}
        renderEmptyList={renderEmptyList}
        breakpoint={breakpoints.medium}
      />

      <EmployeeAssignment
        showAssignment={showAssignmentModal}
        employeeId={employeeAssignmentId}
        onPressClose={() => {
          setShowAssignmentModal(false);
          setEmployeeAssignmentId("");
        }}
      />
    </>
  );
};
