import { DatePicker } from "@components/forms/DatePicker";
import { FormEnter } from "@components/forms/FormEnter";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { Button } from "../../../components/forms/Button";
import { OperationInfo, PaidTicketDocument, TicketRelay } from "../../../graphql/crm";
import { encodeDate, encodeDateISO, getToday } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { validateRequired } from "../../../utils/validations";
import { useLoading } from "../../context/LoadingContext";

type Props = {
  visible: boolean;
  onPressClose: () => void;
  ticket: TicketRelay | undefined;
};

type PaidForm = {
  paymentDate: string;
};

export const TicketUpdateStatusModal = ({ visible, onPressClose, ticket }: Props) => {
  const [, paidTicket] = useUrqlMutation(PaidTicketDocument);
  const { Field, submitForm } = useForm<PaidForm>({
    paymentDate: {
      initialValue: ticket?.paymentDate != null ? encodeDateISO(ticket?.paymentDate) : getToday(),
      validate: validateRequired,
    },
  });

  const { setLoading } = useLoading();

  const handleSubmit = () => {
    submitForm(values => {
      setLoading(true);

      paidTicket({
        input: {
          id: ticket?.id as string,
          paymentDate: values.paymentDate !== undefined ? encodeDate(values.paymentDate) : "",
        },
      }).mapOk(data => {
        match(data.paidTicket)
          .with({ __typename: "OperationInfo" }, () =>
            showToast({
              variant: "error",
              title: (data?.paidTicket as OperationInfo)?.messages[0]?.message ?? "",
              autoClose: true,
            }),
          )
          .otherwise(() => {
            onPressClose();
          });
        setLoading(false);
      });
    });
  };

  return (
    <LakeModal
      visible={visible}
      onPressClose={onPressClose}
      title={t("invoices.purchase.paidTitle")}
    >
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {() => (
          <FormEnter onSubmit={handleSubmit}>
            <Box
              onTouchEnd={e => {
                e.stopPropagation();
              }}
            >
              <Field name="paymentDate">
                {({ value, onChange, error }) => (
                  <DatePicker
                    format={locale.dateFormat}
                    firstWeekDay={locale.firstWeekday}
                    value={value}
                    onChange={onChange}
                    label={t("invoices.paymentDate") + "*"}
                    error={error}
                  />
                )}
              </Field>
            </Box>

            <Space height={24} />

            <Box alignItems="end">
              <Button onPress={handleSubmit} icon={<SaveIcon />} reverse={true}>
                {t("common.save")}
              </Button>
            </Box>
          </FormEnter>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
