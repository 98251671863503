import { Box } from "@swan-io/lake/src/components/Box";
import { LakeCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { RadioGroup } from "@swan-io/lake/src/components/RadioGroup";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { useUser } from "../../features/context/UserContext";
import {
  CompanyPaymentMethod,
  CreateCompanyPaymentMethodDocument,
  OperationInfo,
  PaymentMethodTypes,
  Static,
  UpdateCompanyPaymentMethodDocument,
} from "../../graphql/crm";
import { t } from "../../utils/i18n";
import { Router } from "../../utils/routes";
import { CompanyPaymentMethodState } from "../../utils/types";
import {
  handlerFieldErrors,
  tapError,
  validateOptional,
  validateRequired,
} from "../../utils/validations";
import { Button } from "../forms/Button";
import Input from "../forms/Input";

const styles = StyleSheet.create({
  row: {
    marginBottom: 16,
  },
});

type Props = {
  data?: CompanyPaymentMethod;
  paymentMethods: Static[];
};

export const PaymentMethodsForm = ({ data, paymentMethods }: Props) => {
  const { reloadCompany } = useUser();

  const { Field, setFieldValue, setFieldError, submitForm, listenFields } =
    useForm<CompanyPaymentMethodState>({
      type: { initialValue: data?.type.toLowerCase() ?? "bank", validate: validateRequired },
      name: { initialValue: data?.name ?? "", validate: validateRequired },
      iban: { initialValue: data?.iban ?? "", validate: validateOptional(validateIban) },
      default: { initialValue: data?.default ?? false },
      archived: { initialValue: data?.archived ?? false },
    });

  const [, create] = useUrqlMutation(CreateCompanyPaymentMethodDocument);
  const [, update] = useUrqlMutation(UpdateCompanyPaymentMethodDocument);

  const onSave = () => {
    submitForm(values => {
      if (data == null) {
        create({
          input: {
            ...values,
            name: values.name ?? "",
            type: values.type?.toUpperCase() as PaymentMethodTypes,
          },
        })
          .tapError(tapError)
          .mapOk(data => {
            match(data.createCompanyPaymentMethod)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<CompanyPaymentMethodState>(
                  data.createCompanyPaymentMethod as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                reloadCompany();
                Router.push("CompanyPaymentMethods");
              });
          });
      } else {
        update({
          input: {
            id: data?.id.toString(),
            name: values.name ?? "",
            iban: values.iban ?? "",
            default: values.default ?? false,
            archived: values.archived ?? false,
          },
        })
          .tapError(tapError)
          .mapOk(data => {
            match(data.updateCompanyPaymentMethod)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<CompanyPaymentMethodState>(
                  data.updateCompanyPaymentMethod as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                reloadCompany();
                Router.push("CompanyPaymentMethods");
              });
          });
      }
    });
  };

  const [paymentMethodType, setPaymentMethodType] = useState(data?.type.toLowerCase() ?? "bank");

  useEffect(() => {
    const listener = listenFields(["type"], ({ type }) => {
      if (type.value != "bank") {
        setFieldValue("iban", "");
      }
      setPaymentMethodType(type.value);
    });

    return () => listener();
  }, [listenFields]);

  return (
    <>
      <Field name="type">
        {({ onChange, value, error }) => (
          <RadioGroup
            direction="row"
            items={paymentMethods ?? []}
            value={value}
            onValueChange={onChange}
            error={error}
            hideErrors={error == undefined}
          />
        )}
      </Field>

      <Box style={styles.row}>
        <Field name="name">
          {Input({
            label: t("common.name"),
          })}
        </Field>
      </Box>

      <Box style={styles.row}>
        <Field name="iban">
          {({ value, onChange, error, ref }) => (
            <LakeLabel
              label={t("contact.iban")}
              render={id => (
                <LakeTextInput
                  id={id}
                  ref={ref}
                  value={printIbanFormat(value ?? "")}
                  error={error}
                  hideErrors={error === undefined}
                  disabled={paymentMethodType != "bank"}
                  onChangeText={onChange}
                />
              )}
            />
          )}
        </Field>
      </Box>

      <Box direction="row" alignItems="center" justifyContent="spaceBetween">
        <Box direction="row">
          <Field name="default">
            {({ value, onChange, error }) => (
              <Pressable onPress={() => onChange(!value)}>
                <Box direction="row" alignItems="center">
                  <LakeCheckbox value={value} />
                  <Space width={8} />
                  <Text>{t("common.main")}</Text>
                </Box>

                {error != null && <Text>{error}</Text>}
              </Pressable>
            )}
          </Field>

          <Space width={16} />

          <Field name="archived">
            {({ value, onChange, error }) => (
              <Pressable onPress={() => onChange(!value)}>
                <Box direction="row" alignItems="center">
                  <LakeCheckbox value={value} />
                  <Space width={8} />
                  <Text>{t("common.archived")}</Text>
                </Box>

                {error != null && <Text>{error}</Text>}
              </Pressable>
            )}
          </Field>
        </Box>

        <Space width={16} />
        <Button onPress={onSave}>{t("common.save")}</Button>
      </Box>
    </>
  );
};
