import dayjs from "dayjs";
import { locale } from "./i18n";

export const encodeDateTime = (date: string, time: string) => {
  const dateTime = dayjs(`${date} ${time}`, `${locale.dateFormat} ${locale.timeFormat}`);
  return dateTime.isValid() ? dateTime.toISOString() : "";
};

export const encodeDate = (date: string) => {
  const dateTime = dayjs(date, locale.dateFormat);
  const formattedDate = dateTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
  return dateTime.isValid() ? formattedDate : "";
};

export const encodeShortDate = (date: string) => {
  const dateTime = dayjs(date, locale.dateFormat);
  const formattedDate = dateTime.format("YYYY-MM-DD");
  return dateTime.isValid() ? formattedDate : "";
};

export const encodeDateISO = (date: string) => {
  const dateTime = dayjs(`${date}`);
  return dateTime.isValid() ? dateTime.format(`${locale.dateFormat}`) : "";
};

export const isToday = (date: string) => {
  const today = dayjs().format(locale.dateFormat);
  return date === today;
};

export const diffDays = (from: string, to: string) => {
  return Math.round(
    (new Date(encodeDate(to)).getTime() - new Date(encodeDate(from)).getTime()) / 86400000,
  );
};

export const getToday = () => dayjs().format(locale.dateFormat);

export const addDaysToDate = (date: string, days: number) =>
  dayjs(date, locale.dateFormat).add(days, "day").format(locale.dateFormat);

export const getFirstDayOfMonth = () => dayjs().startOf("month").format(locale.dateFormat);
export const countDaysOfMonth = () => dayjs().daysInMonth();
export const getLastDayOfMonth = () => dayjs().endOf("month").format(locale.dateFormat);

export const isDateInCurrentQuarter = (date: string, grace: boolean = false) => {
  if (date == null) {
    return false;
  }

  const value = new Date(date);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentQuarter = Math.floor(currentMonth / 3) + 1;

  const quarterStartDate = new Date(currentYear, (currentQuarter - 1) * 3, 1);

  let quarterEndDate = new Date(currentYear, currentQuarter * 3, 0);
  if (grace) {
    if (currentQuarter === 4) {
      quarterEndDate = new Date(currentYear + 1, 0, 30);
    } else {
      quarterEndDate = new Date(currentYear, currentQuarter * 3, 20);
    }
  }

  return value >= quarterStartDate && value <= quarterEndDate;
};
