import { Space } from "@swan-io/lake/src/components/Space";
import { FaArrowUp } from "react-icons/fa6";
import { View } from "react-native";
import { match } from "ts-pattern";
import { NavigationItem } from "../../../components/NavigationItem";
import { NavigationSubitem } from "../../../components/NavigationSubitem";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";

export const Navigation = () => {
  const route = Router.useRoute(["CrmContactsList"]);

  return (
    <View role="navigation">
      <NavigationItem to={Router.CrmContactsList()} label={t("contacts")} />

      <NavigationItem
        to={Router.CrmContactsList({ type: ["customer"] })}
        label={t("common.customers")}
      />

      {match(route)
        .with({ name: "CrmContactsList", params: { type: ["customer"] } }, () => (
          <>
            <NavigationSubitem
              to={Router.CrmContactsList({ new: "customer" })}
              label={t("invoices.newCustomer")}
              icon="add-circle-regular"
            />

            <Space height={12} />
          </>
        ))
        .otherwise(() => null)}

      <NavigationItem
        to={Router.CrmContactsList({ type: ["supplier"] })}
        label={t("common.suppliers")}
      />

      {match(route)
        .with({ name: "CrmContactsList", params: { type: ["supplier"] } }, () => (
          <>
            <NavigationSubitem
              to={Router.CrmContactsList({ new: "supplier" })}
              label={t("invoices.newSupplier")}
              icon="add-circle-regular"
            />

            <Space height={12} />
          </>
        ))
        .otherwise(() => null)}

      <Space height={24} />

      <NavigationItem
        to={Router.CrmContactsList({ import: "" })}
        renderIcon={<FaArrowUp />}
        label={t("common.import")}
      />
    </View>
  );
};
