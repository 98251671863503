import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useEffect, useState } from "react";
import { StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { DecimalInput } from "../../../components/DecimalInput";
import { Button } from "../../../components/forms/Button";
import Input from "../../../components/forms/Input";
import Select from "../../../components/forms/Select";
import {
  CreateProductDocument,
  Product,
  SalesCategory,
  SalesInvoiceCreateStaticDocument,
  Static,
  UpdateProductDocument,
} from "../../../graphql/crm";
import { fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { subscriptionError, useSubscription } from "../../../utils/subscription";
import { CreateProductState } from "../../../utils/types";
import { validateRequired } from "../../../utils/validations";
import { extractSalesStatic } from "../utils";

const styles = StyleSheet.create({
  grid: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "75%",
    alignSelf: "flex-end",
    gap: 48,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
});

type Props = {
  product?: Product;
  onPressClose?: () => void;
  showCancel: boolean;
};

export const ProductEditor = ({ product, onPressClose, showCancel }: Props) => {
  const [subcategories, setSubcategories] = useState<Static[]>([]);
  const { Field, submitForm, resetForm, getFieldState, listenFields, setFieldValue } =
    useForm<CreateProductState>({
      description: { initialValue: product?.description ?? "", validate: validateRequired },
      unitType: { initialValue: product?.unitType ?? "" },
      unitPrice: { initialValue: product?.unitPrice as string, validate: validateRequired },
      vat: { initialValue: product?.vat ?? "", validate: validateRequired },
      irpf: { initialValue: product?.irpf ?? "" },
      category: { initialValue: product?.category ?? "" },
      subcategory: { initialValue: product?.subcategory ?? "" },
    });

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const [, createProduct] = useUrqlMutation(CreateProductDocument);
  const [, updateProduct] = useUrqlMutation(UpdateProductDocument);

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  const categories = extractSalesStatic(data, "saleCategory");
  const ventaProductos = extractSalesStatic(data, "ventaProductos");
  const trabajoEmpresas = extractSalesStatic(data, "trabajoEmpresas");
  const subvenciones = extractSalesStatic(data, "subvenciones");
  const otrosIngresos = extractSalesStatic(data, "otrosIngresos");
  const ingresosFinancieros = extractSalesStatic(data, "ingresosFinancieros");
  const resultadosExtraordinarios = extractSalesStatic(data, "resultadosExtraordinarios");

  function changeFields(category: string) {
    match(category)
      .with("VENTA_PRODUCTOS", () => setSubcategories(ventaProductos))
      .with("TRABAJO_EMPRESAS", () => setSubcategories(trabajoEmpresas))
      .with("SUBVENCIONES", () => setSubcategories(subvenciones))
      .with("OTROS_INGRESOS", () => setSubcategories(otrosIngresos))
      .with("INGRESOS_FINANCIEROS", () => setSubcategories(ingresosFinancieros))
      .with("RESULTADOS_EXTRAORDINARIOS", () => setSubcategories(resultadosExtraordinarios))
      .otherwise(() => setSubcategories([]));
  }

  useEffect(() => {
    const listener = listenFields(["category"], ({ category }) => {
      changeFields(category?.value);
    });

    changeFields(getFieldState("category").value);

    return () => listener();
  }, [listenFields, data]);

  const unitType = extractSalesStatic(data, "unitType");
  const vatType = extractSalesStatic(data, "vatType");
  const irpfType = extractSalesStatic(data, "irpfType");

  const { showUpgrade } = useSubscription();

  const onSave = () => {
    submitForm(values => {
      if (product === undefined) {
        createProduct({
          input: {
            description: values.description ?? "",
            unitType: values.unitType,
            unitPrice: parseFloat(values.unitPrice ?? ""),
            vat: values.vat ?? "",
            irpf: values.irpf ?? "",
            category: (values.category as SalesCategory) ?? null,
            subcategory: values.subcategory ?? null,
          },
        }).mapOk(data => {
          match(data.createProduct)
            .with({ __typename: "OperationInfo" }, info => {
              showUpgrade({
                title: t("plan.limitTitle"),
                description: subscriptionError(info),
              });
            })
            .otherwise(() => onPressClose?.());
        });
      } else {
        updateProduct({
          input: {
            id: product.id,
            description: values.description ?? "",
            unitType: values.unitType,
            unitPrice: parseFloat(values.unitPrice ?? ""),
            vat: values.vat ?? "",
            irpf: values.irpf ?? "",
            category: (values.category as SalesCategory) ?? null,
            subcategory: values.subcategory ?? null,
          },
        }).mapOk(() => {
          onPressClose?.();
        });
      }
    });
  };

  return (
    <>
      <Box style={styles.grid}>
        <Field name="description">
          {Input({
            label: t("invoices.description"),
            required: true,
          })}
        </Field>

        <Field name="unitType">
          {Select({
            label: t("invoices.unitType"),
            items: unitType,
            hasEmptyRow: true,
          })}
        </Field>
      </Box>

      <Space height={24} />

      <Box style={styles.grid}>
        <Field name="unitPrice">
          {({ value, onChange, error }) => (
            <LakeLabel
              label={t("invoices.unitPrice")}
              extra={() => <Text style={styles.red}>*</Text>}
              render={id => (
                <DecimalInput id={id} value={value} error={error} onChangeDecimal={onChange} />
              )}
            />
          )}
        </Field>

        <Field name="vat">
          {Select({
            label: t("invoices.iva"),
            items: vatType,
            required: true,
          })}
        </Field>
      </Box>

      <Box style={styles.grid}>
        <Field name="irpf">
          {Select({
            label: t("invoices.irpf"),
            items: irpfType,
            hasEmptyRow: true,
          })}
        </Field>

        <Field name="category">
          {Select({
            label: "Categoria",
            items: categories.map(({ name, value }) => ({ name, value: value.toUpperCase() })),
            hasEmptyRow: true,
            customOnChange: value => {
              setFieldValue("category", value);
              setFieldValue("subcategory", "");
            },
          })}
        </Field>
      </Box>

      <Box style={styles.grid}>
        {getFieldState("category").value && (
          <Field name="subcategory">
            {Select({
              label: "Sub-categoria",
              items: subcategories,
              hasEmptyRow: true,
            })}
          </Field>
        )}
      </Box>

      <Space height={24} />

      <Box style={showCancel === true && styles.gap} alignItems="end">
        {showCancel === true ? (
          <Button onPress={onPressClose} mode="secondary">
            {t("common.cancel")}
          </Button>
        ) : null}

        <Button onPress={onSave} icon={<SaveIcon />}>
          {t("common.save")}
        </Button>
      </Box>
    </>
  );
};
