import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { AsyncData, Result } from "@swan-io/boxed";
import { LinkConfig } from "@swan-io/lake/src/components/FixedListView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ReactElement, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { P, match } from "ts-pattern";
import { DateCell } from "../../../components/forms/DateCell";
import { DefaultCell } from "../../../components/forms/DefaultCell";
import { PriceCell } from "../../../components/forms/PriceCell";
import { SalesInvoiceCreateStaticDocument, SalesInvoiceRelayQuery } from "../../../graphql/crm";
import { encodeDateISO } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { tapError } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { extractSalesStatic } from "../utils";
import { ColumnHeader } from "./ColumnHeader";
import KebabMenu from "./KebabMenu";
import { ProjectAssignmentModal } from "./ProjectAssignmentModal";
import { SalesUpdateStatusModal } from "./SalesUpdateStatusModal";
import { SendModal } from "./SendModal";
import { StatusCell } from "./StatusCell";

const styles = StyleSheet.create({
  header: {
    fontWeight: "600",
    "--color-gray-900": COLORS.WHITE,
    "--text-medium-font-size": 12,
  },
});

type Props = {
  invoices: SalesInvoiceRelayQuery[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  setActiveInvoice: (invoice: SalesInvoiceRelayQuery) => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
  getRowLink: (item: LinkConfig<SalesInvoiceRelayQuery, ExtraInfo>) => ReactElement;
  sortBy?: string;
  setSort?: (columnId: string) => void;
};
type ExtraInfo = {
  onChangeStatus: () => void;
};

export const DetailsList = ({
  invoices,
  loading,
  onEndReached,
  activeRowId,
  setActiveInvoice,
  getRowLink,
  onActiveRowChange,
  renderEmptyList,
  onRefreshRequest,
  sortBy,
  setSort,
}: Props) => {
  const [showSentModal, setShowSentModal] = useState(false);
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState("0");
  const [chargeDate, setChargeDate] = useState("");
  const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<SalesInvoiceRelayQuery>();

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  match(data).with(AsyncData.P.Done(Result.P.Error(P.select())), tapError);

  const paymentMethods = extractSalesStatic(data, "paymentMethods");

  const StatusTag = ({
    item,
    extraInfo: { onChangeStatus },
  }: {
    item: SalesInvoiceRelayQuery;
    extraInfo: ExtraInfo;
  }) => (
    <StatusCell
      status={item.status ?? ""}
      text={item.statusDescription.toString() ?? ""}
      paymentDate={encodeDateISO(item.paymentDate ?? "")}
      paymentMethod={paymentMethods.find(pm => pm.id === item.paymentMethod)?.name ?? ""}
      chargeMatch={chargeMatch(item)}
      align="left"
      onChangeStatus={() => {
        if (item.status !== "CHARGED") {
          onChangeStatus();
        }
      }}
      invoiceId={String(item.id)}
      setInvoiceId={setInvoiceId}
      onAction={() => setChargeDate(encodeDateISO(item.chargeMatch?.executionDate ?? ""))}
    />
  );

  const columns: ColumnConfig<SalesInvoiceRelayQuery, ExtraInfo>[] = [
    {
      id: "issueDate",
      width: 120,
      title: t("invoices.issueDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DateCell date={item.issueDate ?? ""} align="left" />,
      sortable: true,
    },
    {
      id: "seriesCurrent",
      width: 140,
      title: t("invoices.seriesNumber"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.seriesCurrent ?? "-"} />,
      sortable: true,
    },
    {
      id: "customer",
      width: "grow",
      title: t("contact.customer"),
      renderTitle: ({ title }) => <ColumnHeader text={title} />,
      renderCell: ({ item }) => <DefaultCell data={item.customer?.name ?? ""} />,
      sortable: true,
    },
    {
      id: "status",
      width: "grow",
      title: t("common.status"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="left" />,
      renderCell: ({ item, extraInfo: { onChangeStatus } }) => (
        <StatusTag item={item} extraInfo={{ onChangeStatus }} />
      ),
      sortable: true,
    },
    {
      id: "totalTaxBase",
      width: 140,
      title: t("invoices.taxBase"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.totalTaxBase as number} align="right" />,
      sortable: true,
    },
    {
      id: "vat",
      width: 100,
      title: t("invoices.iva"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={Number(item.totalVat)} align="right" />,
    },
    {
      id: "irpf",
      width: 100,
      title: t("invoices.irpf"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.totalIrpf as number} align="right" />,
    },
    {
      id: "total",
      width: 100,
      title: t("invoices.total"),
      renderTitle: ({ title }) => <ColumnHeader text={title} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.total as number} align="right" />,
      sortable: true,
    },
    {
      id: "options",
      width: 100,
      title: "",
      renderTitle: ({ title }) => <ColumnHeader text={title} align="center" />,
      renderCell: ({ item }) => (
        <KebabMenu
          invoice={item}
          setShowSentModal={setShowSentModal}
          setInvoice={setInvoice}
          setActiveInvoice={setActiveInvoice}
          setShowAssignmentModal={setShowAssignmentModal}
          onRefreshRequest={onRefreshRequest}
        />
      ),
    },
  ];

  const smallColumns: ColumnConfig<SalesInvoiceRelayQuery, ExtraInfo>[] = columns.filter(c =>
    ["issueDate", "customer", "total", "options"].includes(c.id),
  );

  const chargeMatch = (invoice: SalesInvoiceRelayQuery) => {
    if (invoice.chargeMatch !== null) {
      const date = encodeDateISO(invoice.chargeMatch?.executionDate ?? "");
      return t("invoices.charge.received", { chargeDate: date });
    }
  };

  return (
    <>
      <PlainListView
        withoutScroll={true}
        data={invoices}
        keyExtractor={item => item.id as string}
        headerHeight={36}
        rowHeight={63}
        headerStyle={styles.header as ViewStyle}
        headerBackgroundColor="#EDEDED"
        groupHeaderHeight={36}
        extraInfo={{ onChangeStatus: () => setUpdateStatusModal(true) }}
        columns={columns}
        smallColumns={smallColumns}
        activeRowId={activeRowId}
        onActiveRowChange={onActiveRowChange}
        loading={loading}
        onEndReached={onEndReached}
        getRowLink={getRowLink}
        renderEmptyList={renderEmptyList}
        breakpoint={breakpoints.medium}
        sortBy={sortBy}
        setSort={setSort}
      />

      <SendModal
        visible={showSentModal}
        onPressClose={() => {
          setShowSentModal(false);
        }}
        invoice={invoice}
      />

      <ProjectAssignmentModal
        showAssignment={showAssignmentModal}
        invoiceId={invoice?.id as string}
        type="SALES"
        onPressClose={() => {
          onRefreshRequest();
          setShowAssignmentModal(false);
        }}
      />

      <SalesUpdateStatusModal
        visible={updateStatusModal}
        onPressClose={() => {
          onRefreshRequest();
          setUpdateStatusModal(false);
        }}
        invoiceId={invoiceId}
        chargeDate={chargeDate}
      />
    </>
  );
};
