import { Box } from "@swan-io/lake/src/components/Box";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { StyleSheet } from "react-native";
import { toSnake } from "ts-case-convert";
import { ColumnsMapping, EmployeeColumnsMapping, ErrorDetail } from "../graphql/crm";
import { common } from "../styles/common";
import { t } from "../utils/i18n";
import { COLORS } from "../values/colors";

const styles = StyleSheet.create({
  tileContainerOk: {
    paddingHorizontal: 20,
    paddingVertical: 12,
    "--text-color": COLORS.SUCCESS800,
    "--text-regular-font-size": 14,
    "--text-regular-font-weight": "500",
    backgroundColor: COLORS.SUCCESS100,
  },
  tileContainerError: {
    paddingHorizontal: 20,
    paddingVertical: 12,
    "--text-color": COLORS.DESTRUCTIVE800,
    "--text-regular-font-size": 14,
    backgroundColor: COLORS.DESTRUCTIVE100,
  },
  tileContainerMapping: {
    width: "100%",
    maxWidth: 1280,
    paddingHorizontal: 40,
    backgroundColor: COLORS.NEUTRAL50,
  },
  table: {
    gap: 8,
  },
});

type Props = {
  errors: ErrorDetail[] | undefined;
  mappedColumns?: ColumnsMapping | EmployeeColumnsMapping;
};

export const ImportStepReview = ({ errors, mappedColumns }: Props) => {
  return (
    <>
      <Space height={24} />

      {errors?.length === 0 && (
        <Tile style={styles.tileContainerOk}>
          <LakeText>{t("import.step.checkOk")}</LakeText>
        </Tile>
      )}

      {errors !== undefined && errors?.length > 0 && (
        <Tile style={styles.tileContainerError}>
          <LakeText style={common.weight500}>Hemos detectado los siguientes errores</LakeText>

          {errors?.map((row: ErrorDetail) => (
            <LakeText key={row.rowNumber + row.field}>
              {"Fila: " + row.rowNumber + ": " + row.field + " - " + row.message}
            </LakeText>
          ))}
        </Tile>
      )}

      <Space height={24} />

      {mappedColumns !== undefined && (
        <Tile title={t("import.step.columnMapping")} style={styles.tileContainerMapping}>
          <Box direction="column" style={styles.table}>
            {Object.entries(mappedColumns as object)
              .filter(([key]) => key !== "__typename")
              .map(([key, value]) => (
                <Box key={key} direction="row" style={styles.table}>
                  <LakeText style={common.weight600}>{value}</LakeText>
                  <LakeText>-&gt; {toSnake(key)}</LakeText>
                </Box>
              ))}
          </Box>
        </Tile>
      )}
    </>
  );
};
