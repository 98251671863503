import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { StyleSheet} from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { ChangeRecoveryPasswordDocument, OperationInfo } from "../graphql/crm";
import { t } from "../utils/i18n";
import { ChangeRecoveryPasswordState } from "../utils/types";
import { handlerFieldErrors, tapError, validateRequired } from "../utils/validations";
import { Params } from "@swan-io/chicane/dist/types";
import { Button } from "./forms/Button";
import Input from "./forms/Input";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  form: {
    width: '100%',
    paddingVertical: 16,
  }
});

export const ChangePasswordForm = ({ params }:{params:Params}) => {
  const { Field, setFieldError, submitForm } = useForm<ChangeRecoveryPasswordState>({
    newPassword: { initialValue: "", validate: validateRequired },
    passwordRepeat: {
      initialValue: "",
      validate: (value, values) => {
        if (value !== values.getFieldState("newPassword").value) {
          return t("common.passwordsNotMatch");
        }
      },
    },
  });

  const [, update] = useUrqlMutation(ChangeRecoveryPasswordDocument);

  const onSave = () => {
    submitForm(values => {
      update({
        input: {
          newPassword: values.newPassword ?? "",
          uid: params.uid as string ?? "",
          token: params.token as string ?? "",
        },
      })
        .tapError(tapError)
        .mapOk(data => {
          match(data.changeRecoveryPassword)
            .with({ __typename: "OperationInfo" }, () =>
              handlerFieldErrors<ChangeRecoveryPasswordState>(
                data.changeRecoveryPassword as OperationInfo,
                setFieldError,
              ),
            )
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("common.savedPasswordSuccessfully"),
                autoClose: true,
              });
              Router.push("SigninPage");
            });
        });
    });
  };

  return (
      <Box style={styles.form}>

        <Space height={16} />
        
        <Field name="newPassword">
          {Input({
            label: t("common.newPassword"),
            required: true,
            secureTextEntry: true,
          })}
        </Field>

        <Field name="passwordRepeat">
          {Input({
            label: t("common.passwordRepeat"),
            required: true,
            secureTextEntry: true,
          })}
        </Field>

        <Space height={16} />
        <Button mode="primary" onPress={onSave}>{t("common.save")}</Button>
      </Box>
  );
};
