import { ErrorView } from "@components/ErrorView";
import { Heading } from "@components/Heading";
import { InputRounded } from "@components/forms/Input";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { FocusTrapRef } from "@swan-io/lake/src/components/FocusTrap";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ListRightPanel } from "@swan-io/lake/src/components/ListRightPanel";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useUrqlPaginatedQuery, useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useCallback, useMemo, useRef, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import {
  InputMaybe,
  Product,
  ProductsDocument,
  ProductsRelay,
  SalesInvoiceCreateStaticDocument,
} from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { extractSalesStatic } from "../utils";
import { ProductCreate } from "./ProductCreate";
import { ProductRightPanel } from "./ProductRightPanel";
import { DetailsList } from "./ProductsDetailsList";
import { InvoiceFiltersState, ListFilter } from "./ProductsListFilter";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
  },
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  container: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
});

const NUM_TO_RENDER = 7;

export const List = () => {
  const route = Router.useRoute(["InvoicesProductsList"]);
  const params = match(route)
    .with({ name: "InvoicesProductsList" }, ({ params }) => params)
    .otherwise(() => {});

  const filters: InvoiceFiltersState = useMemo(() => {
    return {
      search: params?.search,
      unitType: params?.unitType,
      irpf: params?.irpf,
      vat: params?.vat,
    } as InvoiceFiltersState;
  }, [params?.search, params?.unitType, params?.irpf, params?.vat]);

  const { data: staticData } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  const unitType = extractSalesStatic(staticData, "unitType");
  const irpfType = extractSalesStatic(staticData, "irpfType");
  const vatType = extractSalesStatic(staticData, "vatType");

  const { data, nextData, reload, setAfter } = useUrqlPaginatedQuery(
    {
      query: ProductsDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          search: params?.search,
          unitType: { inList: params?.unitType as InputMaybe<string[]> },
          irpf: { inList: params?.irpf as InputMaybe<string[]> },
          vat: { inList: params?.vat as InputMaybe<string[]> },
        },
      },
    },
    [filters],
  );

  const [activeId, setActiveId] = useState<string | null>(null);
  const [product, setProduct] = useState<ProductsRelay | undefined>(undefined);

  const onActiveRowChange = useCallback(() => {}, []);

  const products = data
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ products }) => Option.fromNullable(products))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const onViewDetails = useCallback(
    (currentId: string) => {
      setProduct(products.filter(({ id }) => id === currentId)?.[0] as ProductsRelay);
    },
    [filters, products],
  );

  const onClose = () => {
    setActiveId(null);
    Router.push("InvoicesProductsList", { ...filters });
    reload();
  };

  const panelRef = useRef<FocusTrapRef | null>(null);

  return (
    <View style={styles.root}>
      <View style={commonStyles.fill} role="main">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          style={styles.header}
        >
          <InputRounded>
            <LakeSearchField
              placeholder={t("common.search")}
              initialValue={""}
              onChangeText={search =>
                Router.push("InvoicesProductsList", {
                  ...filters,
                  search,
                })
              }
            />
          </InputRounded>

          <Box direction="row" justifyContent="end" alignItems="end">
            <ListFilter
              filters={filters}
              onChange={filters => Router.push("InvoicesProductsList", filters)}
              unitType={unitType}
              irpf={irpfType}
              vat={vatType}
            />
          </Box>
        </Box>

        <Heading title={t("invoices.productsServices")} />

        <Box style={styles.container}>
          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: data => (
                  <DetailsList
                    products={products as ProductsRelay[]}
                    onActiveRowChange={onActiveRowChange}
                    activeRowId={activeId ?? undefined}
                    onRefreshRequest={reload}
                    onEndReached={() => {
                      if (data?.products.pageInfo.hasNextPage === true) {
                        setAfter(data?.products?.pageInfo.endCursor ?? undefined);
                      }
                    }}
                    loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER }}
                    getRowLink={({ item }) => (
                      <Pressable
                        onPress={() => {
                          setActiveId(item.id as string);
                          onViewDetails(item.id as string);
                        }}
                      />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                  />
                ),
              }),
          })}
        </Box>
      </View>

      <ProductCreate
        visible={params?.create !== undefined}
        product={product as unknown as Product}
        onPressClose={onClose}
      />

      <ListRightPanel
        ref={panelRef}
        keyExtractor={item => item.id as string}
        activeId={activeId}
        onActiveIdChange={setActiveId}
        onClose={onClose}
        items={products}
        render={product => (
          <ProductRightPanel product={product as ProductsRelay} onClose={onClose} large={false} />
        )}
        closeLabel={t("common.closeButton")}
        previousLabel={t("common.next")}
        nextLabel={t("common.back")}
      />
    </View>
  );
};
