import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { OperationInfo, UpdateUserDocument, User } from "../graphql/crm";
import { borderColorVariants, fontColorVariants } from "../styles/constants";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { UserState } from "../utils/types";
import { handlerFieldErrors, tapError, validateRequired } from "../utils/validations";
import { ChangePassword } from "./ChangePassword";
import { Heading } from "./Heading";
import { UserSubscription } from "./UserSubscription";
import { Button } from "./forms/Button";
import Input, { LabelInput } from "./forms/Input";

const styles = StyleSheet.create({
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
  },
  form: {
    maxWidth: 800,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 24,
    marginBottom: 16,
  },
  note: {
    color: fontColorVariants.neutral500,
  },
});

type Props = {
  user: User;
};

export const UserInfo = ({ user }: Props) => {
  const route = Router.useRoute(["UserRoot", "UserEdit", "UserChangePassword", "UserSubscription"]);

  const { Field, setFieldError, submitForm } = useForm<UserState>({
    firstName: { initialValue: user?.firstName ?? "", validate: validateRequired },
    lastName: { initialValue: user?.lastName ?? "", validate: validateRequired },
  });

  const [, updateUser] = useUrqlMutation(UpdateUserDocument);

  const onSave = () => {
    submitForm(values => {
      updateUser({
        input: {
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
        },
      })
        .tapError(tapError)
        .mapOk(data => {
          match(data.updateUser)
            .with({ __typename: "OperationInfo" }, () =>
              handlerFieldErrors<UserState>(data.updateUser as OperationInfo, setFieldError),
            )
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("common.savedSuccessfully"),
                autoClose: true,
              });
            });
        });
    });
  };

  return match(route)
    .with({ name: "UserRoot" }, () => null)
    .with({ name: "UserEdit" }, () => (
      <>
        <Box direction="row" alignItems="center" justifyContent="end" style={styles.header}></Box>
        <Heading title={t("hr.generalData")} />

        <View style={styles.form}>
          <Box direction="row" alignItems="start" style={styles.row}>
            <Field name="firstName">
              {Input({
                label: t("common.firstName"),
                required: true,
              })}
            </Field>

            <Field name="lastName">
              {Input({
                label: t("common.lastName"),
                required: true,
              })}
            </Field>
          </Box>

          <LabelInput
            label={t("common.email")}
            value={user?.email ?? ""}
            onChange={() => {}}
            disabled={true}
          />

          <Space height={16} />
          <Text style={styles.note}>{t("user.infoNotEditable")}</Text>
          <Space height={16} />

          <Box direction="row" justifyContent="end">
            <Button onPress={onSave}>{t("common.save")}</Button>
          </Box>
        </View>
      </>
    ))
    .with({ name: "UserSubscription" }, () => <UserSubscription />)
    .with({ name: "UserChangePassword" }, () => <ChangePassword />)
    .run();
};
