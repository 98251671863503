import { FullViewportLayer } from "@swan-io/lake/src/components/FullViewportLayer";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import {
  NerPurchaseInvoice,
  NerPurchaseInvoiceDocument,
  PurchaseInvoiceRelay,
} from "../../../graphql/crm";
import { t } from "../../../utils/i18n";
import { subscriptionError, useSubscription } from "../../../utils/subscription";
import { COLORS } from "../../../values/colors";
import { LeftPanel } from "./LeftPanel";
import { PurchaseEditor } from "./PurchaseEditor";

const styles = StyleSheet.create({
  gradient1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-80%, -70%)",
    opacity: 0.9,
    width: "727px",
    height: "727px",
    backgroundImage:
      "radial-gradient(33.64% 25.02% at 50% 50%, rgba(89, 226, 255, 0.22) 0%, rgba(108, 168, 243, 0.00) 100%)",
  },
  gradient2: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(0, -30%)",
    width: "50%",
    height: "70%",
    backgroundImage:
      "radial-gradient(67.22% 50% at 50% 50%, rgba(255, 100, 51, 0.15) 0%, rgba(245, 228, 255, 0.00) 100%)",
  },
  background: {
    position: "absolute",
    bottom: 0,
    backgroundColor: COLORS.WHITE,
    width: "100dvw",
    height: "50dvh",
  },
  container: {
    backgroundImage: COLORS.BACKGROUND,
  },
  grid: {
    display: "grid",
    placeContent: "center",
    width: "100dvw",
    minHeight: "100dvh",
    paddingVertical: 16,
    transitionProperty: "transform",
    transitionDuration: "500ms",
    transitionTimingFunction: "ease-in-out",
  },
  invoiceContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    transition: "all 0.5s ease",
    width: "100dvw",
    height: "100%",
  },
  openContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    transition: "all 0.5s ease",
    width: "95dvw",
  },
});

type Props = {
  visible?: boolean;
  invoice?: PurchaseInvoiceRelay;
  onRefreshRequest?: () => void;
};

export const PurchaseCreate = ({ invoice, visible = false, onRefreshRequest }: Props) => {
  const [open, setOpen] = useState(visible);
  const [invoiceNer, setInvoiceNer] = useState<NerPurchaseInvoice | undefined>(undefined);
  const [ner, setNer] = useState(false);
  const [file, setFile] = useState("");

  const [, uploadInvoice] = useUrqlMutation(NerPurchaseInvoiceDocument);

  const { showUpgrade } = useSubscription();

  const onUpload = async (file: File) => {
    await uploadInvoice({ file }).mapOk(data => {
      match(data.nerPurchaseInvoice)
        .with({ __typename: "OperationInfo" }, info =>
          match(info.messages[0])
            .with({ field: "ocrQuantity" }, { field: "cloudCapacity" }, () => {
              setFile("");
              showUpgrade({
                title: t("plan.limitTitle"),
                description: subscriptionError(info),
              });
            })
            .otherwise(() => {
              throw new Error(info?.messages[0]?.message ?? "");
            }),
        )
        .with({ __typename: "NerPurchaseInvoice" }, response => {
          setInvoiceNer(response as NerPurchaseInvoice);
          setNer(true);
          if (Boolean(response.invoice.file as string)) {
            setFile(response.invoice?.file ?? "");
          }
        });
    });
  };

  useEffect(() => {
    setFile(invoice?.file ?? "");
  }, [invoice?.file]);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  return (
    <FullViewportLayer visible={visible}>
      <ScrollView style={styles.container}>
        <View style={styles.gradient1} />
        <View style={styles.gradient2} />
        <View style={styles.background} />

        <ResponsiveContainer breakpoint={1280}>
          {({ large }) => (
            <View style={styles.grid}>
              <View style={[styles.invoiceContainer, open && styles.openContainer]}>
                {(!invoice || invoice.file != null) && large && (
                  <LeftPanel inputFile={file} onUpload={onUpload} setOpen={setOpen} />
                )}

                <View>
                  <PurchaseEditor
                    invoice={invoice}
                    invoiceNer={invoiceNer}
                    onRefreshRequest={onRefreshRequest}
                    ner={ner}
                  />
                </View>
              </View>
            </View>
          )}
        </ResponsiveContainer>
      </ScrollView>
    </FullViewportLayer>
  );
};
