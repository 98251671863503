import { Box } from "@swan-io/lake/src/components/Box";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useUser } from "../features/context/UserContext";
import { backgroundColorVariants, borderColorVariants } from "../styles/constants";
import { t } from "../utils/i18n";
import { CloseIcon } from "../utils/icons";
import { Router } from "../utils/routes";
import { CompanyAddressForm } from "./CompanyAddressForm";
import { CompanyAddressesList } from "./CompanyAddressesList";
import { Heading } from "./Heading";
import { RightPanel } from "./RightPanel";

const styles = StyleSheet.create({
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
  },
  container: {
    backgroundColor: backgroundColorVariants.white,
    padding: 8,
  },
  tabs: {
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
    width: 700,
    maxWidth: "100vw",
  },
});

export const CompanyAddresses = () => {
  const route = Router.useRoute(["CompanyAddresses"]);
  const { id } = route?.params ?? {};

  const { company, reloadCompany } = useUser();

  const address = company?.addresses?.find(a => a.id.toString() === id);

  const fiscalAddresses = company?.addresses
    ?.filter(a => a.type === "fiscal")
    .sort(a => (a.default ? -1 : 1));
  const socialAddresses = company?.addresses
    ?.filter(a => a.type === "social")
    .sort(a => (a.default ? -1 : 1));

  const [activeTabId, setActiveTabId] = useState("fiscal");

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="end" style={styles.header}></Box>
      <Heading title={t("common.addresses")} />

      <View style={styles.container}>
        <View style={styles.tabs}>
          <TabView
            tabs={[
              {
                id: "fiscal",
                label: t("company.fiscal"),
              },
              {
                id: "social",
                label: t("company.social"),
              },
            ]}
            otherLabel=""
            activeTabId={activeTabId}
            onChange={setActiveTabId}
          />
        </View>

        {activeTabId === "fiscal" && (
          <CompanyAddressesList
            data={fiscalAddresses ?? []}
            onActiveRowChange={() => null}
            activeRowId={undefined}
            onRefreshRequest={reloadCompany}
            onEndReached={() => null}
            getRowLink={({ item }) => (
              <Pressable
                onPress={() => {
                  Router.push("CompanyAddresses", { id: item.id.toString() });
                }}
              />
            )}
            renderEmptyList={() => (
              <FixedListViewEmpty
                icon="lake-transfer"
                borderedIcon={true}
                title={t("common.empty")}
              />
            )}
          />
        )}

        {activeTabId === "social" && (
          <CompanyAddressesList
            data={socialAddresses ?? []}
            onActiveRowChange={() => null}
            activeRowId={undefined}
            onRefreshRequest={reloadCompany}
            onEndReached={() => null}
            getRowLink={({ item }) => (
              <Pressable
                onPress={() => {
                  Router.push("CompanyAddresses", { id: item.id.toString() });
                }}
              />
            )}
            renderEmptyList={() => (
              <FixedListViewEmpty
                icon="lake-transfer"
                borderedIcon={true}
                title={t("common.empty")}
              />
            )}
          />
        )}
      </View>

      <RightPanel
        visible={id != null}
        overlay={true}
        onPressClose={() => Router.push("CompanyAddresses")}
        style={styles.rightpanel}
      >
        <View style={styles.rightpanel}>
          <Pressable onPress={() => Router.push("CompanyAddresses")}>
            <CloseIcon />
          </Pressable>

          <Space height={24} />
          <CompanyAddressForm address={address} />
        </View>
      </RightPanel>
    </>
  );
};
