import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { View } from "react-native";
import { match } from "ts-pattern";
import { LogoutDocument } from "../graphql/crm";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { NavigationItem } from "./NavigationItem";
import { NavigationSubitem } from "./NavigationSubitem";

export const UserCompanyNavigation = () => {
  const route = Router.useRoute(["CompanyAddresses", "CompanyPaymentMethods"]);

  const [, logout] = useUrqlMutation(LogoutDocument);

  const onLogout = () => {
    logout({}).mapOk(() => {
      Router.replace("SigninPage");
    });
  };

  return (
    <View role="navigation">
      <NavigationItem to={Router.UserEdit()} label={t("hr.generalData")} />
      <NavigationItem to={Router.CompanyEdit()} label={t("common.company")} />
      <NavigationItem to={Router.CompanyAddresses()} label={t("common.addresses")} />

      {match(route)
        .with({ name: "CompanyAddresses" }, () => (
          <>
            <NavigationSubitem
              to={Router.CompanyAddresses({ id: "" })}
              label={t("company.newAddress")}
              icon="add-circle-regular"
            />

            <Space height={12} />
          </>
        ))
        .otherwise(() => null)}

      <NavigationItem to={Router.CompanyPaymentMethods()} label={t("common.paymentMethods")} />

      {match(route)
        .with({ name: "CompanyPaymentMethods" }, () => (
          <>
            <NavigationSubitem
              to={Router.CompanyPaymentMethods({ id: "" })}
              label={t("company.newPaymentMethod")}
              icon="add-circle-regular"
            />

            <Space height={12} />
          </>
        ))
        .otherwise(() => null)}

      <NavigationItem to={Router.UserSubscription()} label={t("plan.subscription")} />
      <Space height={24} />
      <NavigationItem to={Router.UserChangePassword()} label={t("common.changePassword")} />
      <NavigationItem to="" label={t("common.logout")} onPress={onLogout} />
    </View>
  );
};
