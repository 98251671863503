import verticalDots from "@assets/icons/dots-vertical.svg";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useState } from "react";
import { GoArchive } from "react-icons/go";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Popover from "react-native-popover-view";
import { match } from "ts-pattern";
import { useLoading } from "../../features/context/LoadingContext";
import { CompanyPaymentMethod, UpdateCompanyPaymentMethodDocument } from "../../graphql/crm";
import { borderColorVariants } from "../../styles/constants";
import { t } from "../../utils/i18n";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: 4,
    width: "100%",
  },
  menu: {
    padding: 8,
    borderRadius: 8,
  },
  menuItem: {
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
  },
  menuText: {
    marginLeft: 8,
  },
});

type KebabMenuProps = {
  paymentMethod: CompanyPaymentMethod;
  onRefreshRequest: () => void;
};

const KebabMenu = ({ paymentMethod, onRefreshRequest }: KebabMenuProps) => {
  const { setLoading } = useLoading();
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [, updateCompanyPaymentMethod] = useUrqlMutation(UpdateCompanyPaymentMethodDocument);

  const handleArchive = () => {
    setLoading(true);
    updateCompanyPaymentMethod({
      input: {
        id: paymentMethod.id,
        name: paymentMethod.name,
        iban: paymentMethod.iban,
        default: paymentMethod.default,
        archived: !paymentMethod.archived,
      },
    }).mapOk(data => {
      setLoading(false);
      match(data.updateCompanyPaymentMethod)
        .with({ __typename: "CompanyPaymentMethod" }, _ => {
          showToast({
            title: !paymentMethod.archived ? t("common.archived") : t("common.unarchived"),
            variant: "success",
            autoClose: true,
          });
          setShowPopover(false);
          onRefreshRequest();
        })
        .with({ __typename: "OperationInfo" }, (info: { messages: { message: string }[] }) => {
          showToast({
            title: info.messages[0]?.message as string,
            variant: "error",
            autoClose: true,
          });
        });
    });
  };

  return (
    <View
      style={styles.container}
      onTouchEnd={e => {
        e.stopPropagation();
      }}
    >
      <Popover
        isVisible={showPopover}
        popoverStyle={styles.menu}
        onRequestClose={() => setShowPopover(false)}
        from={
          <TouchableOpacity onPress={() => setShowPopover(true)}>
            <AutoWidthImage sourceUri={verticalDots} height={23} />
          </TouchableOpacity>
        }
      >
        <View
          style={styles.menu}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          <TouchableOpacity onPress={handleArchive} style={styles.menuItem}>
            <GoArchive size={20} />

            <Text style={styles.menuText}>
              {paymentMethod.archived ? t("common.unarchive") : t("common.archive")}
            </Text>
          </TouchableOpacity>
        </View>
      </Popover>
    </View>
  );
};

export default KebabMenu;
