import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Link } from "@swan-io/lake/src/components/Link";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { Image, ScrollView, Text, View, useWindowDimensions } from "react-native";
import { combineValidators, useForm } from "react-ux-form";
import { match } from "ts-pattern";
import background from "../assets/img/bg-signup.webp";
import control from "../assets/img/control.svg";
import favicon from "../assets/img/favicon.svg";
import logoWhite from "../assets/img/logo-trexx-white.svg";
import receipt from "../assets/img/receipt.svg";
import virtual from "../assets/img/virtual.svg";
import { InfoMarkenting } from "../components/InfoMarketing";
import { Button } from "../components/forms/Button";
import Checkbox from "../components/forms/Checkbox";
import { FormEnter } from "../components/forms/FormEnter";
import Input from "../components/forms/Input";
import { useUser } from "../features/context/UserContext";
import { SignUpDocument } from "../graphql/crm";
import { common } from "../styles/common";
import { styles } from "../styles/sign";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateChecked, validateEmail, validateRequired } from "../utils/validations";

type FormState = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  termsAndConditions: boolean;
  acceptMailMarketing: boolean;
};

export type InfoStep = {
  id: number;
  subtitle: string;
  description: string;
  icon: string;
};

const info: InfoStep[] = [
  {
    id: 1,
    subtitle: "Gráficas visuales y tablas muy claras con datos clave.",
    description:
      "Esto facilita la toma de decisiones basadas en datos y contribuye a la eficacia y el éxito general de la empresa.",
    icon: receipt,
  },
  {
    id: 2,
    subtitle: "Céntrate en tu negocio",
    description:
      "Las herramientas que ayudan a los contables a automatizar tareas y gestionar eficientemente, en tus manos.",
    icon: virtual,
  },
  {
    id: 3,
    subtitle: "Genera presupuestos y albaranes",
    description:
      "Envía presupuestos, albaranes y personalizadas, con la misma herramienta y enlázalos con tus documentos privados.",
    icon: control,
  },
];

export const SignupPage = () => {
  const { user } = useUser();

  if (user) {
    Router.replace("SummaryRoot");
  }

  const [, crmSignup] = useUrqlMutation(SignUpDocument);

  const { Field, submitForm } = useForm<FormState>({
    firstName: { initialValue: "", validate: validateRequired },
    lastName: { initialValue: "", validate: validateRequired },
    email: { initialValue: "", validate: combineValidators(validateRequired, validateEmail) },
    password: { initialValue: "", validate: validateRequired },
    companyName: { initialValue: "", validate: validateRequired },
    termsAndConditions: {
      strategy: "onSubmit",
      initialValue: false,
      validate: validateChecked,
    },
    acceptMailMarketing: {
      initialValue: false,
    },
  });

  const { width } = useWindowDimensions();
  const isDesktop = width > 980;

  const handleSubmit = () => {
    submitForm((values: Partial<FormState>) => {
      crmSignup({
        input: {
          email: values.email ?? "",
          password: values.password ?? "",
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
          companyName: values.companyName ?? "",
          acceptMailMarketing: values.acceptMailMarketing ?? false,
        },
      })
        .mapOk(data => {
          match(data.signup)
            .with({ __typename: "OperationInfo" }, data => {
              showToast({
                variant: "error",
                title: data.messages[0]?.message ?? "",
                autoClose: true,
              });
            })
            .otherwise(() => {
              Router.replace("ActivateRoot");
            });
        })
        .tapError(error => {
          showToast({ variant: "error", title: error.message, autoClose: true });
        });
    });
  };

  return (
    <ScrollView centerContent={true} style={common.bgPrimary50}>
      <Box direction={!isDesktop ? "column" : "row"} style={isDesktop && common.h100}>
        {isDesktop && (
          <Box style={[styles.left, styles.bgGradient]}>
            <View style={styles.leftContainer}>
              <AutoWidthImage sourceUri={logoWhite} height={34} alt="Trexx" />
            </View>

            <View style={styles.leftContainer}>
              <Text style={styles.h4}>
                Generador de facturas integrado Ahorra tiempo administrando y pagando facturas,
                además puedes sincronízarlas.
              </Text>

              {info.map(({ id, subtitle, description, icon }) => (
                <InfoMarkenting
                  key={id}
                  subtitle={subtitle}
                  description={description}
                  icon={icon}
                />
              ))}
            </View>

            <Image
              source={background}
              style={styles.background}
              alt="Background image signup page"
            />
          </Box>
        )}

        <Box
          style={[styles.form, isDesktop && common.flexBasis50]}
          direction="column"
          justifyContent="center"
        >
          <FormEnter onSubmit={handleSubmit}>
            <View style={styles.box}>
              <Box
                direction="row"
                alignItems="center"
                justifyContent="spaceBetween"
                style={styles.welcome}
              >
                <AutoWidthImage sourceUri={favicon} height={37} alt="Trexx" />

                <Link to={Router.SigninPage()} style={[styles.link, common.underline]}>
                  {t("signxx.have_account")}
                </Link>
              </Box>

              <Text style={styles.h5}>
                En Trexx estamos encantados de verte por aquí, por favor dinos quién eres
              </Text>

              <Field name="firstName">
                {Input({
                  label: t("common.firstName"),
                  placeholder: "¿Cuál es tu nombre?",
                })}
              </Field>

              <Field name="lastName">
                {Input({
                  label: t("common.lastName"),
                  placeholder: "¿Cuáles son tus apellidos?",
                })}
              </Field>

              <Field name="email">
                {Input({
                  label: t("signxx.yourEmail"),
                  placeholder: "Dirección de correo electrónico",
                  inputMode: "email",
                })}
              </Field>

              <Field name="password">
                {Input({
                  label: t("signxx.password"),
                  placeholder: "Introduzca 8 caracteres o más",
                  secureTextEntry: true,
                })}
              </Field>

              <Field name="companyName">
                {Input({
                  label: t("common.companyName"),
                  placeholder: "¿Dónde trabajas?",
                })}
              </Field>

              <Field name="termsAndConditions">
                {Checkbox({
                  children: (
                    <Text style={styles.small}>
                      Al registrarte, aceptas nuestra{" "}
                      <Link to={"/"} style={common.underline}>
                        términos de uso
                      </Link>
                    </Text>
                  ),
                })}
              </Field>

              <Field name="acceptMailMarketing">
                {Checkbox({
                  children: (
                    <Text style={styles.small}>
                      También acepta recibir correos electrónicos de marketing relacionados con
                      productos de Wiser, de los cuales puede cancelar su suscripción en cualquier
                      momento.
                    </Text>
                  ),
                })}
              </Field>

              <Button size="large" mode="secondary" style="outlined" onPress={handleSubmit}>
                Crea tu cuenta gratuita
              </Button>
            </View>
          </FormEnter>
        </Box>
      </Box>
    </ScrollView>
  );
};
