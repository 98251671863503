import { Calendar } from "@components/Calendar";
import { Heading } from "@components/Heading";
import { Sidebar } from "@components/Sidebar";
import { InputRounded } from "@components/forms/Input";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { ProfitAndLossDocument, ProfitAndLossOutput } from "../../../graphql/crm";
import { encodeDate, encodeDateISO, getToday } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { Navigation } from "../components/Navigation";
import { ProfitAndLossAnalysis } from "../components/ProfitAndLossAnalysis";
import { ProfitAndLossReport } from "../components/ProfitAndLossReport";

const styles = StyleSheet.create({
  background: {
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: COLORS.BACKGROUND,
  },
  container: {
    flexShrink: 1,
    flexGrow: 1,
  },
  desktopContainer: {
    flexDirection: "row",
    width: "100%",
    marginHorizontal: "auto",
  },
  root: {
    ...commonStyles.fill,
  },
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  heading: {
    zIndex: 1,
  },
  filters: {
    gap: 16,
  },
});

export const ProfitAndLossArea = () => {
  const { desktop } = useResponsive();

  const firstDayOfYear = `${new Date().getFullYear()}-01-01`;

  const [dates, setDates] = useState({
    dateFrom: encodeDateISO(firstDayOfYear),
    dateTo: getToday(),
  });

  useEffect(() => {
    Router.push("ReportsProfitAndLossRoot", {
      dateFrom: encodeDate(dates.dateFrom),
      dateTo: encodeDate(dates.dateTo),
    });
  }, [dates]);

  const route = Router.useRoute(["ReportsProfitAndLossRoot"]);
  const params = match(route)
    .with({ name: "ReportsProfitAndLossRoot" }, ({ params }) => params)
    .otherwise(() => {});

  const { data: summaryData } = useUrqlQuery({
    query: ProfitAndLossDocument,
    variables: {
      filters: {
        dateRange: { gte: params?.dateFrom, lte: params?.dateTo },
      },
    },
  });

  const summary = summaryData
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.profitAndLoss))
    .getWithDefault({} as ProfitAndLossOutput);

  return (
    <WithPartnerAccentColor color="#498AF2">
      <SkipToContent />

      <View style={styles.background}>
        <View style={[styles.container, desktop && styles.desktopContainer]}>
          {desktop && (
            <Sidebar>
              <Navigation />
            </Sidebar>
          )}

          <ScrollView>
            <View style={styles.root}>
              <View style={commonStyles.fill} role="main">
                <Box
                  direction="row"
                  alignItems="center"
                  justifyContent="spaceBetween"
                  style={styles.header}
                >
                  <InputRounded>
                    <LakeSearchField
                      placeholder={t("common.search")}
                      initialValue={""}
                      onChangeText={() => {}}
                    />
                  </InputRounded>

                  <Box direction="row" style={styles.filters}>
                    <Calendar
                      dateTo={new Date(encodeDate(dates.dateTo))}
                      dateFrom={new Date(encodeDate(dates.dateFrom))}
                      setDates={setDates}
                    />
                  </Box>
                </Box>

                <ResponsiveContainer breakpoint={breakpoints.medium}>
                  {({ large }) => (
                    <Box
                      direction={large ? "row" : "column"}
                      alignItems="center"
                      justifyContent="spaceBetween"
                      style={styles.heading}
                    >
                      <Heading title={t("reports.profitAndLoss")} />

                      <View>
                        <Space height={12} />
                      </View>
                    </Box>
                  )}
                </ResponsiveContainer>

                <ProfitAndLossAnalysis summary={summary} />
                <ProfitAndLossReport summary={summary} />
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </WithPartnerAccentColor>
  );
};
