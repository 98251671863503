import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Image, ScrollView, Text, View, useWindowDimensions } from "react-native";
import background from "../assets/img/bg-signin.webp";
import control from "../assets/img/control.svg";
import favicon from "../assets/img/favicon.svg";
import logoWhite from "../assets/img/logo-trexx-white.svg";
import logo from "../assets/img/logo-trexx.svg";
import receipt from "../assets/img/receipt.svg";
import virtual from "../assets/img/virtual.svg";
import { InfoMarkenting } from "../components/InfoMarketing";
import { common } from "../styles/common";
import { styles } from "../styles/sign";
import { t } from "../utils/i18n";
import { Params } from "@swan-io/chicane/dist/types";
import { ChangePasswordForm } from "../components/ChangePasswordForm";

export type InfoStep = {
    id: number;
    subtitle: string;
    description: string;
    icon: string;
  };
  
  const info: InfoStep[] = [
    {
      id: 1,
      subtitle: "Generador de facturas integrado",
      description: "Ahorra tiempo administrando y pagando facturas, además puedes sincronízarlas.",
      icon: receipt,
    },
    {
      id: 2,
      subtitle: "Iban virtual infinito",
      description:
        "Agregue subcuentas con números de cuenta designados para realizar un mejor presupuesto.",
      icon: virtual,
    },
    {
      id: 3,
      subtitle: "Controla las finanzas de tu negocio",
      description: "Sin saldo mínimo y con transacciones ilimitadas",
      icon: control,
    },
  ];

export const ChangePasswordPage = ({ params }:{params:Params}) => {
  const { width } = useWindowDimensions();
  const isDesktop = width > 980;

  return (
    <ScrollView centerContent={true} style={common.bgPrimary50}>
    <Box direction={!isDesktop ? "column" : "row"} style={isDesktop && common.h100}>
        {isDesktop && (
          <Box style={[styles.left, styles.bgGradient]}>
            <View style={styles.leftContainer}>
              <AutoWidthImage sourceUri={logoWhite} height={34} alt="Trexx" />
            </View>

            <View style={styles.leftContainer}>
              <Text style={styles.h4}>Tú creaste tu negocio, nosotros le ayudamos a crecer</Text>

              {info.map(({ id, subtitle, description, icon }) => (
                <InfoMarkenting
                  key={id}
                  subtitle={subtitle}
                  description={description}
                  icon={icon}
                />
              ))}
            </View>

            <Image
              source={background}
              style={styles.background}
              alt="Background image sigin page"
            />
          </Box>
        )}

        <Box
          style={[styles.form, isDesktop && common.flexBasis50]}
          direction="column"
          justifyContent="center"
        >
            <View style={styles.box}>
            {!isDesktop ? (
                <AutoWidthImage sourceUri={logo} height={32} alt="Trexx" />
              ) : (
                <AutoWidthImage sourceUri={favicon} height={37} alt="Trexx" />
              )}

              <Box
                direction="row"
                alignItems="center"
                justifyContent="spaceBetween"
                style={styles.welcome}
              >

                <Text style={styles.h5} >{t("common.changePassword")}</Text>
                <ChangePasswordForm params={params} />
              </Box>
            </View>
        </Box>
      </Box>
    </ScrollView>
  );
};
