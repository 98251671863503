import { Button } from "@components/forms/Button";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useState } from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { Customer, CustomerAddress } from "../../../graphql/crm";
import { borderColorVariants, fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { AddressForm } from "./AddressForm";
import { AddressesList } from "./AddressesList";

const styles = StyleSheet.create({
  header: {
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    color: fontColorVariants.label,
  },
});

type Props = {
  customer: Customer;
  type: string;
  reload: () => void;
};

export const Addresses = ({ customer, type, reload }: Props) => {
  const [address, setAddress] = useState<CustomerAddress>();

  const handleClose = () => {
    setAddress(undefined);
    reload();
  };

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="spaceBetween" style={styles.header}>
        <Text style={styles.title}>
          {type == "fiscal" ? t("common.fiscalAddresses") : t("common.deliveryAddresses")}
        </Text>

        <Button
          size="small"
          style="outlined"
          reverse={true}
          onPress={() => {
            setAddress({ type } as CustomerAddress);
          }}
        >
          {t("company.newAddress")}
        </Button>
      </Box>

      <AddressesList
        data={customer.addresses
          .filter(address => address.type === type)
          .sort(a => (a.default ? -1 : 1))}
        reload={reload}
        onActiveRowChange={() => null}
        activeRowId={undefined}
        onEndReached={() => null}
        getRowLink={({ item }) => (
          <Pressable
            onPress={() => {
              setAddress(item);
            }}
          />
        )}
        renderEmptyList={() => <Text>{t("common.empty")}</Text>}
      />

      <LakeModal
        visible={address != null}
        title={type == "fiscal" ? t("company.fiscalAddress") : t("address.delivery")}
        maxWidth={740}
        onPressClose={() => {
          setAddress(undefined);
        }}
      >
        <AddressForm customerId={parseInt(customer.id)} address={address} onClose={handleClose} />
      </LakeModal>
    </>
  );
};
