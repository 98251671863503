import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Popover from "react-native-popover-view";
import { match } from "ts-pattern";
import trash from "../../../assets/icons/delete.svg";
import verticalDots from "../../../assets/icons/dots-vertical.svg";
import { DeleteCustomerAddressDocument } from "../../../graphql/crm";
import { borderColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { useLoading } from "../../context/LoadingContext";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    padding: 4,
    width: "100%",
  },
  menu: {
    padding: 8,
    borderRadius: 8,
  },
  menuItem: {
    padding: 8,
    width: 160,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomColor: borderColorVariants.primary,
    borderBottomWidth: 1,
  },
  menuText: {
    marginLeft: 8,
  },
});

type KebabMenuProps = {
  addressId: string;
  onRefreshRequest: () => void;
};

const KebabMenu = ({ addressId, onRefreshRequest }: KebabMenuProps) => {
  const { setLoading } = useLoading();
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [, deleteAddress] = useUrqlMutation(DeleteCustomerAddressDocument);

  const handleDelete = () => {
    setLoading(true);
    deleteAddress({ id: addressId }).mapOk(data => {
      setLoading(false);
      match(data.deleteCustomerAddress)
        .with({ __typename: "DeleteContactOutput" }, _ => {
          showToast({
            title: t("common.deleted"),
            variant: "success",
            autoClose: true,
          });
          setShowPopover(false);
          onRefreshRequest();
        })
        .with({ __typename: "OperationInfo" }, (info: { messages: { message: string }[] }) => {
          showToast({
            title: info.messages[0]?.message as string,
            variant: "error",
            autoClose: true,
          });
        });
    });
  };

  return (
    <View
      style={styles.container}
      onTouchEnd={e => {
        e.stopPropagation();
      }}
    >
      <Popover
        isVisible={showPopover}
        popoverStyle={styles.menu}
        onRequestClose={() => setShowPopover(false)}
        from={
          <TouchableOpacity onPress={() => setShowPopover(true)}>
            <AutoWidthImage sourceUri={verticalDots} height={23} />
          </TouchableOpacity>
        }
      >
        <View
          style={styles.menu}
          onTouchEnd={e => {
            e.stopPropagation();
          }}
        >
          <TouchableOpacity onPress={handleDelete} style={styles.menuItem}>
            <AutoWidthImage sourceUri={trash} height={23} width={23} />
            <Text style={styles.menuText}>{t("common.delete")}</Text>
          </TouchableOpacity>
        </View>
      </Popover>
    </View>
  );
};

export default KebabMenu;
